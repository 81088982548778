const getHeaders = (cookie) => {
  const headers = {
    "Content-Type": "application/json",
  };
  if (cookie) {
    headers["Authorization"] = cookie;
  }
  return headers;
};

const tryJson = async (res) => {
  try {
    return await res.json();
  } catch (_error) {
    return undefined;
  }
};

export const fetchPost = async (url, data, cookie = null) => {
  return await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: getHeaders(cookie),
  })
    .then(async (resp) => {
      if (!resp.ok) {
        const body = await tryJson(resp);
        throw new Error(body ? body.message : "Something went wrong");
      }
      return await resp.json();
    })
    .catch((err) => err);
};

export const fetchGet = async (url, cookie = null) => {
  return await fetch(url, {
    method: "GET",
    headers: getHeaders(cookie),
  })
    .then(async (resp) => {
      if (!resp.ok) {
        const body = await tryJson(resp);
        throw new Error(body ? body.message : "Something went wrong");
      }
      return await resp.json();
    })
    .catch((err) => err);
};
